export class ContentEntryModel {
    constructor(
        public id:number,
        public title: string,
        public name: string = 'Chiang Mustermann',
        public description: string = 'Long text test test test',
        public image: string = null,
        public video:string = null,
        public list_category:string = null,
        public percentage_satisfied:string = null,
        public percentage_neutral:string = null,
        public percentage_unsatisfied:string = null,
        public date:string = null,
        public location:string = null,
        public department:string = null,
        public person_function:string = null,
        public imageLoaded:boolean = false,
        public contentEntryType:string = null,
        public count_positive:string = null,
        public count_neutral:string = null,
        public count_negative:string = null,
        public feedback_positive:string = null,
        public feedback_neutral:string = null,
        public feedback_negative:string = null,
        public voting_all:string = null,
        public videoLoaded:boolean = false
    ){

    }

    // public title: string = 'Testtitle',
    // public name: string = 'Chiang Mustermann',
    // public description: string = 'Long text test test test'  
}
