import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
import { AlertController } from '@ionic/angular';
import { LoadingService } from './loading.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {

  //apiUrl = 'http://localhost:8080/';
  // apiUrl = "http://b2c364d8.ngrok.io/";
  //apiUrl = "https://preview.aktisto.de/"; //STAGING
  // apiUrl = 'https://mylukas-preview03.netgroup.de/employee_api/'; // PROD
  // apiUrl = 'http://quick-start.preview03.netgroup.de/employee_api/'; // PROD
  apiUrl = 'https://www.65549.ng-app.de/employee_api/'; // PROD

  urlParamString = '';

  constructor(private http: HttpClient, private alertController: AlertController, private loading: LoadingService, private authService: AuthService) { }


  getData(): Observable<any[]> {
  	this.loading.present();
    return this.http.get<any[]>(this.apiUrl + this.urlParamString)
      .pipe(
        tap(_ => this.loading.dismiss()),
        catchError(this.handleError('getData()', []))
      );
  }

  setUrlParam(urlParamString:string){
  	this.urlParamString = urlParamString;
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      this.loading.dismiss();
      console.log("GOT AN ERROR oon operation", operation);
      console.log("ERROR:", error);

      //Logout user if token invalid
      if(error.error.message == 'Ungültiger Token'){
        this.authService.logout();
        return of(result as T);
      }

      if(error.error.message == 'netgroup.employee_app.exception.auth.malformed_jwt'){
        this.authService.logout();
        return of(result as T);
      }

      // Show error message to user
      if(error.error.message){this.showErrorMessage(error.error.message);}
      console.log(`${operation} failed. Message: ${error.error.message}`);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  async showErrorMessage(message:string){
  	const alert = await this.alertController.create({
      header: 'Fehler:',
      message: message,
      buttons: [{text: 'OK', cssClass: 'input-btn-dark'}]
    });
    await alert.present(); 	
  }
}
 